import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import BlogCards from "../components/BlogCards"
// ...GatsbyImageSharpFluid

const BlogPage = () => {
  return (
    <Layout>
      <h2>blog page</h2>
    </Layout>
  )
}

export default BlogPage
